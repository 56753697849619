<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!bannerid">Add Banner</h1>
      <h1 v-if="bannerid">Edit Banner</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="banners()">Back To Banners </el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="Banner Added Successfully"
      type="success"
      effect="dark"
      v-if="!bannerid"
    />
    <el-alert
        title="Banner Updated Successfully"
        type="success"
        effect="dark"
        v-if="bannerid"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>
<el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Name"
        prop="name"
      >
       <el-input
          v-model="data.name"
          type="text"
          autocomplete="off"
        />
      </el-form-item></el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
      label="Status"
      prop="status">
      <el-select v-model="data.status">
    <!--Add options when value is id-->
    <el-option v-for="item in selectstatus" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </el-option>
  </el-select>
  </el-form-item>
  </el-col>
</el-row>
  <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right;">
        <div>
        <el-button  size="large" type="primary" icon="el-icon-plus" @click="addimages" >
        </el-button>       
        </div>
        </el-col>
    </el-row>
    <el-row :gutter="20">
        <el-col :span="6">
        <div class="append-col-name">Title</div>
        </el-col>
        <el-col :span="6">
        <div  class="append-col-name">Link</div>
        </el-col>
         <el-col :span="6">
        <div  class="append-col-name">Image</div>
        </el-col>
        <el-col :span="4">
        <div  class="append-col-name">order</div>
        </el-col>
        <el-col :span="2">
        <div  class="append-col-name">Action</div>
        </el-col>
        </el-row>  
        <div v-for="(images_data, index_image) in array_images" :key="index_image">
     
        <el-row :gutter="20" class="row-border-top">
         <el-col :span="6">
          <el-form-item 
          label="&nbsp;"
          > 
          <el-input
          v-model="images_data.title" 
          :id="'title'+index_image"
          type="text"
          />
        </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;" 
        >
        <el-input
          v-model="images_data.link" 
          :id="'title'+index_image"
          type="text"
          />
          </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;"
        >

        <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="images_data.image"
              :limit="1"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleImageUploadSuccess"
              :on-error="handleImageUploadError"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-col>
         
        <el-col :span="4">
        <el-form-item 
        label="&nbsp;">
        <el-input
          v-model="images_data.sort_order" 
          :id="'image'+index_image"
          type="text"
          />
        </el-form-item>        
        </el-col>
        <el-col :span="2">
          <el-form-item  label="&nbsp;">
        <el-button  size="large" type="primary" icon="el-icon-minus" @click="deleteimage(index)"></el-button>
          </el-form-item>
        </el-col>
        </el-row>
      </div>
<el-row :gutter="20" style="text-align: left;">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <el-form-item>
        <input
          id="bannerid"
          v-model="bannerid"
          name="bannerid"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
  </el-col>
</el-row>
</el-form>
</template>
<script>
import BannerService from "@/api/banner.service";
import TinyMce from "@/components/TinyMce";
import authHeader from '@/api/auth-header';

  export default {
    components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the  Name.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        bannerid: this.$route.params.bannerid,
        array_images:[{title:'',link:'',image:[],sort_order:''}],
        array_images_url:[],
        selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
		data: {
          name: null,
          status: "Enabled",
         // images:[],
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {    
    this.setImageUploadUrl();
    if(this.bannerid){
      this.getbanner();
    }
  }, 
    methods: {
       setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response) {
        if(response.code == 200) {
		  this.array_images_url.push({image:response.image_url});
        }
      },
      handleImageUploadError(err) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, array_images) {
        console.log(file, array_images.image)
        //TODO: Delete the file
      },
    addimages: function () {
        this.array_images.push({title:'',link:'',image:[],sort_order:''});
     },
      deleteimage: function (index) {
        this.array_images.splice(index, 1);
        if(index===0)
          this.addimages();
      },

      submitForm(formName) {
        this.data.images=this.array_images;
        this.data.images_url=this.array_images_url;
        this.$refs[formName].validate((valid) => {
          if (valid && !this.bannerid) {
             return BannerService.add(this.data).then(response => {
                this.showAlertAdded=true;
				this.banners();
              });
          } else if (valid && this.bannerid) {
            return BannerService.update(this.bannerid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	banners(){
		this.$router.push("/banners");
	},
	getbanner(){
    return BannerService.get(this.bannerid).then(response => {
        //this.data = response.data.data;
		this.data.status	=response.data.data.status;
		this.data.name		=response.data.data.name;
		console.log(response.data.data.imagesData);
        if(response.data.data.imagesData){
			this.array_images=response.data.data.imagesData;
			//this.array_images_url.push({image:response.data.data.imagesData.imageorg});

        }
		
      });
	},
   
  }
}
</script>
<style lang="scss">
.el-upload-dragger{
  width: 294px;
  height: 145px;
}
</style>
<style lang="scss" scoped>
.el-upload-dragger{
  width: 294px;
  height: 145px;
}
.el-select{
    width: 100%;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>